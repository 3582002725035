import React from "react"

import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
const PortfolioPage = React.lazy(() => import("../components/Portfolio-Page"))

export default function Portfolio() {
  return (
    <Layout>
      <Helmet>
        <link rel="canonical" href="https://codefulcrum.com/portfolio/" />
      </Helmet>

      <React.Suspense fallback={<div />}>
        <PortfolioPage />
      </React.Suspense>
    </Layout>
  )
}
